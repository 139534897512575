import { ReactNode, cloneElement, useMemo } from "react";
import { Tooltip, PlacesType } from "react-tooltip";
import { twMerge } from "tailwind-merge";

const TOOLTIP_ID_PREFIX = "custom-tooltip";
const ID_GEN = {
    next: (() => {
        let id = 1;
        return () => `${TOOLTIP_ID_PREFIX}-${id++}`;
    })()
};

export type TooltipWrapperVariant = "standard" | "small" | "medium";

const tooltipVariantStyle: Record<TooltipWrapperVariant, string> = {
    medium: "!py-2 !px-4 !rounded-md max-w-[274px]",
    standard: "!py-1 !px-2 !rounded-sm",
    small: "!text-caption !rounded !p-1 shadow-[0_1px_4px_rgba(0,0,0,0.2)]"
};

export type TooltipWrapperProps = {
    /** The contents of the tooltip */
    content: ReactNode;
    /** The delay (in ms) before showing the tooltip on hover/click (default: 0) */
    delayShow?: number;
    /** The delay (in ms) before hiding the tooltip after click (default: show until losing focus) */
    delayHide?: number;
    /** If true, only pops the tooltip when the component is clicked, or on hover otherwise (default: false) */
    openOnClick?: boolean;
    /** Where to place the tooltip, relative to the associated children (default: top) */
    place?: PlacesType;
    /** The component to hover the tooltip over */
    children: JSX.Element;
    /** Variant for style of tooltip*/
    variant?: TooltipWrapperVariant;
};

/** A wrapper that adds a hover or click tooltip to its children */
export const TooltipWrapper = ({
    content,
    variant = "standard",
    children,
    ...ttProps
}: TooltipWrapperProps): JSX.Element => {
    const uniqueId = useMemo(() => ID_GEN.next(), []);
    return (
        <>
            <Tooltip
                id={uniqueId}
                className={twMerge(
                    `opaque-tooltip bg-primary text-inverse z-[5]`,
                    tooltipVariantStyle[variant]
                )}
                {...ttProps}
                classNameArrow="hidden"
                offset={variant === "small" ? 4 : 10}
            >
                {content}
            </Tooltip>
            {cloneElement(children, { "data-tooltip-id": uniqueId })}
        </>
    );
};
